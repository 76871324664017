import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from 'axios';
import { Helmet } from 'react-helmet';

axios.defaults.timeout = 10000;
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<>
		<Helmet>
			{/* Google tag (gtag.js) */}
			<script
				async
				src="https://www.googletagmanager.com/gtag/js?id=AW-11468082525"
			></script>
			<script>
				{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-11468082525');`}
			</script>
		</Helmet>
		<App />
	</>
);
