import axios from 'axios';
import React, { useCallback, useState } from 'react';

const PaypalConnectButton = () => {
	const [disabled, setDisabled] = useState(false);
	const paypalConnect = useCallback(() => {
		setDisabled(true);
		axios
			.get('/auth/paypal-link')
			.then(({ data }) => {
				document.location.href = data.link;
			})
			.catch((e) => {
				setDisabled(false);
				console.log('PayPal connect error', e);
			});
	}, []);

	return (
		<button
			className="paypal-connect-button"
			onClick={paypalConnect}
			disabled={disabled}
		>
			PayPal connect
		</button>
	);
};

export default PaypalConnectButton;
