import { useCallback, useEffect, useMemo, useState } from 'react';
import { Categories } from 'utils/constants';
import Dropdown from 'components/Base/Dropdown';
import IconDown from './arrow-down.png';

const TagsSelect = ({ onChange, init = [], cost }) => {
	const [tags, setTags] = useState(init);
	useEffect(() => {
		if (init.length) {
			setTags(init);
		}
	}, [init]);

	const addTag = useCallback((name) => {
		setTags((prev) => [name, ...prev]);
	}, []);

	const removeTag = useCallback((name, event) => {
		event.stopPropagation();
		setTags((prev) => prev.filter((t) => t !== name));
	}, []);

	useEffect(() => {
		onChange(tags);
	}, [onChange, tags]);

	const restCategories = useMemo(() => {
		return cost?.length
			? cost.filter((cat) => !tags.find((tag) => tag[0] === cat[0]))
			: Categories.filter((cat) => !tags.includes(cat));
	}, [tags, cost]);

	return (
		<div className="tags-select">
			<Dropdown
				items={restCategories}
				autoclose={false}
				onChange={(val) => addTag(val)}
			>
				{tags.map((tag) => (
					<div className="tag btn" key={tag} onClick={(e) => removeTag(tag, e)}>
						{Array.isArray(tag) ? (
							tag.map((i, index) => (
								<span key={index}>{i}</span>
							))
						) : (
							<span>{tag}</span>
						)}
					</div>
				))}
				<div className="add btn">
					Add job tags <img className="arrow" src={IconDown} alt="Dropdown" />
				</div>
			</Dropdown>
		</div>
	);
};

export default TagsSelect;
