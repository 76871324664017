import axios from 'axios';
import { useEffect, useState } from 'react';
import Notifications from 'store/notifications';

const TabBackgroundCheck = () => {
	const [isLoadingState, setIsLoadingState] = useState(false);
	const [backgroundCheckStatusState, setBackgroundCheckStatusState] =
		useState('');
	const [backgroundCheckUrlState, setBackgroundCheckUrlState] = useState('');
	const [backgroundCheckResultState, setBackgroundCheckResultState] =
		useState('');
	const [accessToPlatformState, setAccessToPlatformState] = useState('');
	const [
		backgroundCheckAdjudicationState,
		setBackgroundCheckAdjudicationState,
	] = useState('');

	const mapBackgroundCheckStatus = (status, result, adjudication) => {
		switch (status) {
			case 'pending':
				return 'In Progress';
			case 'complete':
				if (result === 'clear') {
					return 'Clear';
				} else if (result === 'consider') {
					if (adjudication === 'engaged') {
						return 'Clear';
					}
					return 'Needs Review';
				}
				return 'Needs Review';
			case 'canceled':
				return 'Canceled';
			case 'not_started':
				return 'Not Started';
			default:
				return 'Incomplete';
		}
	};

	/**
	 * Method to handle clicking on the button to begin a background check
	 */
	const onBeginBackgroundCheckClick = async () => {
		setIsLoadingState(true);
		try {
			const beginBackgroundCheckResponse = await axios.post(
				`/auth/begin-background-check`
			);
			const { status, invitation_url } = beginBackgroundCheckResponse.data;
			setBackgroundCheckStatusState(status);
			setBackgroundCheckUrlState(invitation_url);
		} catch (error) {
			Notifications.create({
				title: 'Error',
				content:
					'There was an error initiating your background check. Please try again or contact customer support.',
			});
		}
		setIsLoadingState(false);
	};

	useEffect(() => {
		const getUserData = async () => {
			const userResponse = await axios.get(`/profile/general`);
			const { status, url, result, adjudication, accessToPlatform } =
				userResponse.data.backgroundCheckInfo;
			setBackgroundCheckStatusState(status);
			setBackgroundCheckUrlState(url);
			setBackgroundCheckResultState(result);
			setBackgroundCheckAdjudicationState(adjudication);
			setAccessToPlatformState(accessToPlatform);
			localStorage.setItem('backgroundCheckStatus', status);
			localStorage.setItem('backgroundCheckResult', result);
			localStorage.setItem('adjucation', adjudication);
			localStorage.setItem('accessToPlatform', accessToPlatform);
		};
		getUserData();
	}, []);

	return (
		<div className="edit-tab">
			{backgroundCheckStatusState && (
				<>
					<p className="desc">
						The status of your background check is:{' '}
						<span className="background-check-status">
							{mapBackgroundCheckStatus(
								backgroundCheckStatusState,
								backgroundCheckResultState,
								backgroundCheckAdjudicationState
							)}
						</span>
						.
					</p>
					{accessToPlatformState ? (
						<p className="desc">Welcome to Emissary!</p>
					) : (
						<>
							<p className="desc">
								In order to ensure the safety of Emissary customers, all
								caregivers must complete a background check through our
								background check partner Checkr. All information will be
								submitted through Checkr. Once the process is complete, you will
								be granted full access to the Emissary platform.{' '}
							</p>

							{backgroundCheckUrlState && (
								<div>
									<p className="desc">
										Follow the link below to complete your background check on
										the Checkr platform.
									</p>
									<a
										className="desc background-check-url"
										href={backgroundCheckUrlState}
										target="_blank"
										rel="noreferrer"
									>
										{backgroundCheckUrlState}
									</a>
									<p className="desc">
										Checkr may ask for additional information after your initial
										submission. If your background check has not finished
										processing, please check your email or visit the Checkr
										candidate portal.
									</p>
									<p className="desc">
										Having issues with your current background check? Click the
										button below to restart the process.
									</p>
								</div>
							)}

							<button
								onClick={onBeginBackgroundCheckClick}
								disabled={isLoadingState}
								className="submit"
							>
								{backgroundCheckStatusState === 'not_started'
									? 'Begin Background Check'
									: 'Restart Background Check'}
							</button>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default TabBackgroundCheck;
