import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Categories } from 'utils/constants';

import Dropdown from 'components/Base/Dropdown';

import IconSearch from './img/search.svg';
import IconArrow from './img/arrow-down.png';
import BaseCheckbox from 'components/Base/BaseCheckbox';

const Search = ({ onSearch }) => {
	const [canSearch, setCanSearch] = useState(false);
	const [search, setSearch] = useState({
		query: '',
		category: '',
		city: '',
		sortDistance: false,
		date: '',
		startTime: '',
		endTime: ''
	});

	const { state } = useLocation();

	useEffect(() => {
		if (state?.search) {
			const query = state.search;
			setSearch((prev) => ({ ...prev, query }));
			onSearch({ query });
		}
	}, [state, onSearch]);

	const handleSearchInputs = useCallback((e) => {
		setCanSearch(true);
		setSearch((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	}, []);

	const handleSearchCategory = useCallback((name) => {
		setCanSearch(true);
		setSearch((prev) => {
			const category = prev.category === name ? '' : name;
			return { ...prev, category };
		});
	}, []);

	const handleSearchAvailability = (e) => {
		const field = e.target.name || e.target.id;
		let value = e.target.value;

		if (field === 'date' && value) {
			const date = new Date(value);
			date.setUTCHours(10, 0, 0, 0);
			value = date.toISOString();
		}

		const mapping = {
			'date': 'date',
			'startTime': 'startTime',
			'endTime': 'endTime'
		};

		setSearch((prev) => {
			const newSearch = {
				...prev,
				[mapping[field]]: value
			};

			setCanSearch(
				newSearch.date &&
				newSearch.startTime &&
				newSearch.endTime
			);

			return newSearch;
		});
	};


	const sortByDistance = useCallback(() => {
		setCanSearch(true);
		setSearch((prev) => ({ ...prev, sortDistance: !prev.sortDistance }));
	}, [search]);

	const startSearch = useCallback(
		(e) => {
			e.preventDefault();
			onSearch(search);
			setCanSearch(false);
		},
		[search, onSearch]
	);

	return (
		<div className="search">
			<form onSubmit={startSearch}>
				<div className="query block">
					<img src={IconSearch} alt="Search" />
					<input
						type="text"
						placeholder="Search by keyword"
						value={search.query}
						onChange={handleSearchInputs}
						name="query"
					/>
				</div>
				<div className="category block">
					<Dropdown items={Categories} onChange={handleSearchCategory} withReset>
						{search.category ? (
							<div className="cat">{search.category}</div>
						) : (
							<div className="placeholder">Category</div>
						)}
						<img src={IconArrow} className="arrow" alt="Arrow" />
					</Dropdown>
				</div>
				<div className="date block">
					<input
						className='date-picker'
						type="date"
						onChange={handleSearchAvailability}
						name="date"
						placeholder="Choose a Date" />
					<input
						className='time-picker'
						type="time"
						onChange={handleSearchAvailability}
						name="startTime"
						placeholder="Start time"
					/>
					<input
						className='time-picker'
						type="time"
						onChange={handleSearchAvailability}
						name="endTime"
						placeholder="End time"
					/>
				</div>
				<div className="city block">
					<input
						type="text"
						placeholder="City"
						value={search.city}
						onChange={handleSearchInputs}
						name="city"
					/>
				</div>
				<button
					className="find block"
					onClick={startSearch}
					disabled={!canSearch}
				>
					Search
				</button>
			</form>
			<div className='sort'>
				<BaseCheckbox onChange={sortByDistance} checked={search.sortDistance} text={"Sort by proximity"} />
			</div>
		</div>
	);
};

export default Search;
