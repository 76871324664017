import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { emptyErrorState, emptyTimeSlots } from 'components/AvailabilityInput/availability-input.constants';
import axios from 'axios';
import AvailabilityInput from 'components/AvailabilityInput/AvailabilityInput';
import { getAvailabilities, parseError, validateAvailabilityTime } from 'utils/helpers';
import Completed from 'components/Base/Completed';
import DatePicker from 'components/DatePicker/DatePicker';


const JobReschedule = () => {
    const [title, setTitle] = useState('');
    const [providerId, setProviderId] = useState('')
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState({
        type: '',
        message: '',
    });

    const [timeData, setTimeData] = useState({
        date: '',
        startTime: '',
        endTime: '',
        weeklyRepeat: false,
    })

    const [availabilitiesState, setAvailabilitiesState] =
        useState(emptyTimeSlots);
    const [availabilitiesErrorState, setAvailabilitiesErrorState] =
        useState(emptyErrorState);

    const navigate = useNavigate();
    const { id: jobId } = useParams();

    const submitJob = useCallback(async () => {
        setError({ type: '', message: '' });

        const isCorrectTime = validateAvailabilityTime(availabilitiesState, timeData.date, timeData.startTime, timeData.endTime);
        if (!isCorrectTime) return setError({ type: 'Time', message: 'Please select a correct time' })
        const body = {
            date: timeData.date,
            timeState: { startTime: timeData.startTime, endTime: timeData.endTime },
            weekly_repeat: timeData.weeklyRepeat || null,
        };

        try {
            const { data } = await axios.patch(`/jobs/${jobId}/reschedule`, body);
            if (data) {
                setCompleted(true);
            }
        }
        catch (e) {
            setError(parseError(e));
        }
    }, [
        jobId,
        timeData,
        navigate,
        availabilitiesState,
    ]);

    useEffect(() => {
        if (!jobId) return
        axios
            .get(`/jobs/${jobId}`)
            .then(({ data }) => {
                setTitle(data.title);
                setTimeData((prev) => ({ ...prev, date: data.date }))
            })
            .catch((e) => {
                navigate('/');
            });
    }, [jobId, navigate]);


    useEffect(() => {
        axios
            .get(`jobs/${jobId}/provider`)
            .then(({ data }) => {
                setProviderId(data._id)
            })
    }, [jobId])

    useEffect(() => {
        if (!providerId) return
        axios
            .get(`jobs/${jobId}/provider`)
            .then(({ data }) => {
                setProviderId(data._id)
                if (data.availabilities) {
                    setAvailabilitiesState(getAvailabilities(data))
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }, [providerId, jobId]);

    return (
        <div className='page job-create'>

            <h1 className="page-title">Reschedule Job {title}</h1>

            <AvailabilityInput
                availabilities={availabilitiesState}
                error={availabilitiesErrorState}
                canEdit={false}
            />

            <DatePicker providerId={providerId} availabilitiesState={availabilitiesState} timeData={timeData} setTimeData={setTimeData} />
            <div className="error">
                <b>{error.type}</b>&nbsp;{error.message}
            </div>

            <button className="submit" onClick={submitJob}>
                Reschedule
            </button>


            <Completed redirect="/jobs/my" show={completed} setShow={setCompleted} />
        </div>
    )
}

export default JobReschedule
