export const daysOfWeekEnum = {
	Sunday: 'Sunday',
	Monday: 'Monday',
	Tuesday: 'Tuesday',
	Wednesday: 'Wednesday',
	Thursday: 'Thursday',
	Friday: 'Friday',
	Saturday: 'Saturday',
};

export const daysOfWeekArray = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const emptyTimeSlots = {
	Monday: [],
	Tuesday: [],
	Wednesday: [],
	Thursday: [],
	Friday: [],
	Saturday: [],
	Sunday: [],
};

export const emptyErrorState = {
	hasError: false,
	message: '',
};

export const emptyTimeSlotState = {
	startTime: '',
	endTime: '',
};
