import { useEffect, useRef, useState } from 'react';

/**
 * Confirmation code component
 */
const ConfirmationCode = ({ setConfirm, children }) => {
	const [confirmCode, setConfirmCode] = useState(['', '', '', '', '', '']);
	const inputs = useRef();

	useEffect(() => {
		setConfirm(confirmCode);
	}, [confirmCode, setConfirm]);

	useEffect(() => {
		inputs.current.children[0].focus();
	}, []);

	const enterConfirm = (target, index) => {
		const val = target.value.slice(-1).trim();
		if (!val || isNaN(val)) return;

		setConfirmCode((code) => {
			code[index] = val;
			target.nextElementSibling?.focus();
			return [...code];
		});
	};

	const onPaste = (event) => {
		const val = event.clipboardData.getData('Text');
		if (isNaN(val) || val.length < 6 || val.length > 6) return;
		setConfirmCode(val.split(''));
		event.preventDefault();
		event.stopPropagation();
	};

	const onKeyDown = (e, index) => {
		if (e.key === 'Backspace') {
			const isEmpty = confirmCode[index] === '';
			setConfirmCode((code) => {
				code[index] = '';
				if (index > 0 && isEmpty) {
					inputs.current.children[index - 1].focus();
				}
				return [...code];
			});
		}
	};


	return (
		<div className="confirm-code">
			<p>Enter confirmation code</p>

			<div className="inputs" ref={inputs}>
				{Array(6)
					.fill(null)
					.map((a, index) => (
						<input
							type="text"
							onInput={(e) => enterConfirm(e.target, index)}
							value={confirmCode[index]}
							key={index}
							onPaste={onPaste}
							onPasteCapture={onPaste}
							onKeyDown={(e) => onKeyDown(e, index)}
						/>
					))}
			</div>
			{children}
		</div>
	);
};

export default ConfirmationCode;
