import { Categories } from 'utils/constants';
import BaseCheckbox from 'components/Base/BaseCheckbox';

// import IconCategory from "./img/cat-icon.svg";
// import IconArrowUp from "./img/cat-arrow-up.svg";
// import IconArrowDown from "./img/cat-arrow-down.svg";

const CategoriesPicker = ({ selectCategory, selected = [], setCategoryRate, rate }) => {
	console.log(selected)
	return (
		<div className="categories">
			{Categories.map((category, index) => (
				<div className="item" key={category}>
					<BaseCheckbox
						onChange={() => selectCategory(category)}
						// onChange={(e) => setCategoryRate(category, e.target.value)}
						checked={selected.some(item => item.title === category)}
						text={category}
					/>
					<div className="price">
						{/* <input
							type="number"
							min="1"
							max="100000"
							step="1"
							className="base-input"
							placeholder="$"
							disabled={!selected.some(item => item.title === category)}
							name={`price-${index}`}
							onChange={(e) => setCategoryRate(category, e.target.value)}
						/> */}
					</div>
				</div>
			))}
		</div>
	);
};

export default CategoriesPicker;
