import moment from 'moment';
import { Link } from 'react-router-dom';
import { getJobTimes } from 'utils/helpers';

import TagsSplit from './TagsSplit';

import IconClock from './img/clock.png';
import IconLocation from './img/location.svg';
import IconLock from './img/lock.svg';
import IconUnlock from './img/unlock.svg';

const CardJob = ({ data }) => {
	return (
		<Link to={`/jobs/${data._id}`} className="card job">
			<div className="title">{data.title}</div>
			<div className="category">
				<img src={IconClock} alt="Time" />
				<span>
					{moment(data.date).format('DD MMMM YYYY')}, {getJobTimes(data.time)}
				</span>
			</div>
			<div className="description">{data.description}</div>

			<TagsSplit tags={data.tags} max={38} />

			<div to={`/customers/${data.creator._id}`} className="creator">
				<div className="name">{`${data.creator.name} ${data.creator.surname}`}</div>
				<div className="location">
					<img src={IconLocation} alt="Location" />
					<span>
						{data.location.city}, {data.location.street}
					</span>
				</div>
			</div>

			<div className="rate">
				<span>Budget:</span>
				<div className="price">
					{data.suggest_price ? (
						<img
							src={IconUnlock}
							title="Can suggest price"
							alt="Icon"
							className="unlock"
						/>
					) : (
						<img
							src={IconLock}
							title="Locked price"
							alt="Icon"
							className="lock"
						/>
					)}
					${data.price}
				</div>
			</div>
		</Link>
	);
};

export default CardJob;
