import { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
	emptyErrorState,
	emptyTimeSlotState,
} from './availability-input.constants';
import moment from 'moment';

const AvailabilityInput = ({
	availabilities,
	originalAvailabilities,
	setOriginalAvailabilities,
	onAddAvailability,
	onEditAvailabilities,
	onSaveAvailabilities,
	doesTimeSlotOverlap,
	error,
	setError,
	canEdit
}) => {
	const [selectedDayState, setSelectedDayState] = useState(null);
	const [isAddingTimeSlotState, setIsAddingTimeSlotState] = useState(false);
	const [isEditingTimeSlotState, setIsEditingTimeSlotState] = useState(false);
	const [newTimeSlotState, setNewTimeSlotState] = useState(emptyTimeSlotState);
	const [errorState, setErrorState] = useState(emptyErrorState);
	const [copiedTime, setCopiedTime] = useState(null);

	const handleDayClick = (day) => {
		if (!isEditingTimeSlotState) return;
		setNewTimeSlotState(emptyTimeSlotState);
		setErrorState(emptyErrorState);
		setError({});
		setSelectedDayState(day);
		setIsAddingTimeSlotState(false);
	};

	const handleCopyTime = (day) => {
		const timeToCopy = availabilities[day];
		setCopiedTime(timeToCopy);
	}

	const handlePasteTime = (day) => {
		if (!copiedTime) return;
		let newAvailabilities = { ...availabilities }
		newAvailabilities[day] = [...copiedTime];
		onEditAvailabilities(newAvailabilities);
	};

	const handleEditExistingTimeSlot = (day, id, field, value) => {
		const updatedAvailabilities = { ...availabilities };
		setErrorState(emptyErrorState);
		setError({});
		updatedAvailabilities[day] = updatedAvailabilities[day].map(
			(availability) => {
				if (availability._id === id) {
					return {
						...availability,
						[field]: value,
					};
				}
				return availability;
			}
		);

		onEditAvailabilities(updatedAvailabilities);
	};

	const handleNewTimeSlotChange = (field, value) => {
		setNewTimeSlotState({
			...newTimeSlotState,
			[field]: value,
		});
	};
	useEffect(() => {
		if (newTimeSlotState.startTime && newTimeSlotState.endTime) {
			onAddAvailability(selectedDayState, newTimeSlotState);

			if (!isEditingTimeSlotState) return;
			setNewTimeSlotState(emptyTimeSlotState);
			setErrorState(emptyErrorState);
			setError({});
			setIsAddingTimeSlotState(false);
		}
	}, [newTimeSlotState]);

	const handleSaveClick = () => {
		if (isAddingTimeSlotState) {
			if (!newTimeSlotState.startTime || !newTimeSlotState.endTime) {
				setErrorState({
					hasError: true,
					message: `Please enter a start and end time on ${selectedDayState}.`,
				});
				return;
			} else if (newTimeSlotState.startTime >= newTimeSlotState.endTime) {
				setErrorState({
					hasError: true,
					message: `Start time must be before end time on ${selectedDayState}.`,
				});
				return;
			} else if (
				doesTimeSlotOverlap(newTimeSlotState, availabilities[selectedDayState])
			) {
				setErrorState({
					hasError: true,
					message: `Time slots overlap on ${selectedDayState}.`,
				});
				return;
			} else {
				setOriginalAvailabilities(availabilities);
				setSelectedDayState(null);
			}
		}

		const availabilitiesSaved = onSaveAvailabilities();
		if (availabilitiesSaved) {
			setNewTimeSlotState(emptyTimeSlotState);
			setErrorState(emptyErrorState);
			setIsAddingTimeSlotState(false);
			setIsEditingTimeSlotState(false);
		} else {
			setErrorState({
				hasError: true,
				message: 'There was an error saving your availabilities.',
			});
		}
	};

	const handleAddTimeSlotClick = () => {
		if (!selectedDayState) {
			setErrorState({
				hasError: true,
				message: 'Please select a day to add a time slot.',
			});
			return;
		}
		setIsAddingTimeSlotState(true);
	};

	const handleEditTimeSlotsClick = () => {
		setIsEditingTimeSlotState(true);
	};

	const handleCancelClick = () => {
		onEditAvailabilities(originalAvailabilities);
		setError({});
		setNewTimeSlotState(emptyTimeSlotState);
		setErrorState(emptyErrorState);
		setIsEditingTimeSlotState(false);
		setIsAddingTimeSlotState(false);
		setSelectedDayState(null);
	};

	const handleRemoveTimeSlot = (id, day) => {
		const updatedAvailabilities = { ...availabilities };

		const index = updatedAvailabilities[day].findIndex(
			(availability) => availability._id === id
		);
		if (index !== -1) {
			updatedAvailabilities[day].splice(index, 1);
		}

		onEditAvailabilities(updatedAvailabilities);
	};

	return (
		<>
			<div className="category-title availability-title">
				<p className="section-title">Availability</p>
				{canEdit && (
					<>
						{isEditingTimeSlotState ? (
							<div className="button-group">
								{!isAddingTimeSlotState && (
									<button
										className="base-button add"
										onClick={handleAddTimeSlotClick}
									>
										Add Time Slot
									</button>
								)}
								<button className="base-button" onClick={() => handleCopyTime(selectedDayState)}>Copy Time</button>
								<button className="base-button" onClick={() => handlePasteTime(selectedDayState)}>Paste Time</button>
								<button className="base-button save" onClick={handleSaveClick}>
									Save
								</button>
								<button className="base-button cancel" onClick={handleCancelClick}>
									Cancel
								</button>
							</div>
						) : (
							<button
								className="add base-button"
								onClick={handleEditTimeSlotsClick}
							>
								Edit Availability
							</button>
						)}
					</>
				)}

			</div>

			{errorState.hasError && (
				<div className="list">
					<div className="length error">{errorState.message}</div>
				</div>
			)}

			{error.message && (
				<div className="list">
					<div className="length error">{error.message}</div>
				</div>
			)}

			<div className="availability-input-container overflow">
				<div className="container">
					{Object.keys(availabilities).map((day) => (
						<div key={day} className="time-slots-container">
							<div
								className={classNames('item', {
									selected: day === selectedDayState,
								})}
								onClick={() => handleDayClick(day)}
							>
								<div className="day">{day}</div>
							</div>

							{availabilities[day].map((timeSlot, index) => (
								<div
									key={`${day}-time-slot-${index}-${timeSlot._id}`}
									className="time-slot-container"
								>
									{isEditingTimeSlotState && (
										<div
											className="delete"
											onClick={() => handleRemoveTimeSlot(timeSlot._id, day)}
										>
											<img
												src={'/img/remove.jpg'}
												alt="Remove"
												height={'12px'}
												width={'12px'}
											/>
										</div>
									)}

									<div className="column-flex time-slot">
										{isEditingTimeSlotState ?
											<>
												<div className="column-flex">
													<label
														htmlFor={`startTime-${day}`}
														className="label-text"
													>
														Start
													</label>
													<input
														id={`startTime-${day}`}
														type="time"
														value={timeSlot.startTime}
														className="input-text"
														disabled={!isEditingTimeSlotState}
														onChange={(e) =>
															handleEditExistingTimeSlot(
																day,
																timeSlot._id,
																'startTime',
																e.target.value
															)
														}
													/>
												</div>

												<div className="column-flex">
													<label htmlFor={`endTime-${day}`} className="label-text">
														End
													</label>
													<input
														id={`endTime-${day}`}
														type="time"
														value={timeSlot.endTime}
														className="input-text"
														disabled={!isEditingTimeSlotState}
														onChange={(e) =>
															handleEditExistingTimeSlot(
																day,
																timeSlot._id,
																'endTime',
																e.target.value
															)
														}
													/>
												</div>
											</> :
											<div className='time-slot-square'>
												<div className="input-text">
													{moment(timeSlot.startTime, "HH:mm").format("h:mm A")}
													-
													<br />
													{moment(timeSlot.endTime, "HH:mm").format("h:mm A")}
												</div>
											</div>
										}
									</div>
								</div>
							))}

							{isEditingTimeSlotState &&
								isAddingTimeSlotState &&
								day === selectedDayState && (
									<div className="column-flex time-slot">
										<div className="column-flex">
											<label htmlFor={`newStartTime`} className="label-text">
												Start
											</label>
											<input
												id={`newStartTime`}
												type="time"
												value={newTimeSlotState.startTime}
												className="input-text"
												onChange={(e) =>
													handleNewTimeSlotChange('startTime', e.target.value)
												}
											/>
										</div>

										<div className="column-flex">
											<label htmlFor={`endTime`} className="label-text">
												End
											</label>
											<input
												id={`endTime`}
												type="time"
												value={newTimeSlotState.endTime}
												className="input-text"
												onChange={(e) =>
													handleNewTimeSlotChange('endTime', e.target.value)
												}
											/>
										</div>
									</div>
								)}
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default AvailabilityInput;
