import axios from 'axios';
import BaseEdit from 'components/Base/BaseEdit'
import BaseModal from 'components/Base/BaseModal'
import React, { useState, useCallback } from 'react'
import { errorNotification } from 'utils/helpers';
import Completed from 'components/Base/Completed';


const JobCancelModal = ({ show, onClose, user, onSubmit }) => {

    const [text, setText] = useState('');
    const [completed, setCompleted] = useState(false);

    const onSend = useCallback(() => {
        if (text.length < 5) {
            return errorNotification(
                'Review text is required. Minimum length - 5 characters'
            );
        }

        axios
            .post(`/profile/${user._id}/message`, {
                content: text,
            })
            .then(({ data }) => {
                setText('');
                setCompleted(true);
            });
    }, [user, text]);

    return (
        <div>
            <BaseModal show={show} onClose={onClose} className="modal-popup">
                <div className="info">


                    <div className="about">
                        <div className="name">
                            Describe the reason for canceling the work
                        </div>
                    </div>
                </div>
                <BaseEdit value={text} onChange={setText} />

                <div className="bottom">
                    <button className="base-button" onClick={() => { onSend(); onSubmit(); }}>
                        Confirm
                    </button>
                </div>

                <Completed show={completed} setShow={setCompleted} onHide={onClose} />
            </BaseModal>
        </div>
    )
}

export default JobCancelModal