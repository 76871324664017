import axios from 'axios';
import classNames from 'classnames';
import { useMemo, useState } from 'react';

import ConfirmationCode from 'components/ConfirmationCode/ConfirmationCode';
import CategoriesPicker from '../../pages/Signup/CategoriesPicker';
import Loader from 'components/Base/Loader';

import IconStepCheck from './img/step-check.svg';
import IconCat from './img/cat-icon.svg';
import { errorNotification } from 'utils/helpers';
import { USStateCodes } from 'utils/constants';
import { Link } from 'react-router-dom';

const Stepper = ({
	step,
	nextStep,
	userType,
	signInfo,
	finish,
	categories,
	selectCategory,
	isGoogleAuth,
	dob,
	onDobChange,
	address,
	onAddressChange,
	setCategoryRate,
	setRate,
	rate,
}) => {
	/** STATES */
	const [waitConfirmEmail, setWaitConfirmEmail] = useState(false);
	const [confirmEmail, setConfirmEmail] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const sendEmailConfirmation = () => {
		if (!waitConfirmEmail) {
			setIsLoading(true);
			axios
				.post('/auth/signup/verify/send', { email: signInfo.email })
				.then(() => {
					setWaitConfirmEmail(true);
				})
				.catch((error) => {
					console.log(error);
					errorNotification(error, true);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			const confirmFilled = confirmEmail.every((i) => i.length);
			if (confirmFilled) {
				setIsLoading(true);
				axios
					.post('/auth/signup/verify/enter', {
						email: signInfo.email,
						code: confirmEmail.join(''),
					})
					.then(() => {
						nextStep();
					})
					.catch((error) => {
						errorNotification(error, true);
						setConfirmEmail([]);
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
		}
	};

	const confirmEmailFilled = useMemo(
		() => confirmEmail.every((i) => i.length),
		[confirmEmail]
	);

	const isAddressComplete = () => {
		if (
			!address.addressLine1 ||
			!address.city ||
			!address.state ||
			!address.zipCode
		) {
			return false;
		}
		return true;
	};

	return (
		<div className="stepper">
			<div className="header">
				<div className="title">Confirm your email</div>
				<div className="desc">
					To complete registration, please confirm your email
				</div>
			</div>

			<div className="wrap">
				<div className="steps">
					{/* <div className={classNames("step", { completed: step > 1, current: step >= 1 })}>
						<img src={IconStepCheck} alt="Step icon" />
						Confirm Phone
					</div> */}

					<div
						className={classNames('step', {
							completed: step > 1,
							current: step >= 1,
						})}
					>
						<img src={IconStepCheck} alt="Step icon" />
						Confirm Email
					</div>

					<div
						className={classNames('step', {
							completed: step > 2,
							current: step >= 2,
						})}
					>
						<img src={IconStepCheck} alt="Step icon" />
						Choose state
					</div>

					{userType === 'provider' && (
						<>
							<div
								className={classNames('step', {
									completed: step > 2,
									current: step >= 2,
								})}
							>
								<img src={IconStepCheck} alt="Step icon" />
								Services Provided
							</div>
							<div
								className={classNames('step', {
									completed: step > 3,
									current: step >= 3,
								})}
							>
								<img src={IconStepCheck} alt="Step icon" />
								Personal Information
							</div>
						</>
					)}
					{/* <div className="step">
						<img src={IconStepCheck} alt="Step icon" />
						Complete Your Profile
					</div> */}
				</div>

				{/* {step === 1 && (
					<div className="content">
						<div className="contact">{signInfo.phone}</div>

						{waitConfirmPhone ? (
							<Confirmation setConfirm={setConfirmPhone} />
						) : (
							<div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>
						)}

						<button className="confirm" onClick={sendPhoneConfirmation} disabled={waitConfirmPhone && !confirmPhoneFilled}>
							{waitConfirmPhone ? "Apply" : "Send Verify Code"}
						</button>
					</div>
				)} */}

				{step === 1 && (
					<div className="content">
						<div className="contact">{signInfo.email}</div>

						{waitConfirmEmail ? (
							<ConfirmationCode setConfirm={setConfirmEmail} />
						) : (
							<div className="desc">
								A confirmation code will be sent to your email. If you don't see
								it in your inbox, check the Promotions and the Spam folders.
							</div>
						)}

						<button
							className="confirm"
							onClick={sendEmailConfirmation}
							disabled={waitConfirmEmail && !confirmEmailFilled}
						>
							{waitConfirmEmail ? 'Apply' : 'Send confirmation code'}
						</button>
					</div>
				)}

				{step === 2 && userType === 'customer' && (
					<div>
						<div className="base-label">State</div>
						<select
							id="state"
							name="state"
							className="base-input"
							value={address.state}
							onChange={(e) =>
								onAddressChange(e.target.name, e.target.value)
							}
						>
							<option value=""> -- Select a state --</option>
							{Object.entries(/*USStateCodes*/ { MA: 'MA' }).map(
								([stateCode, stateName]) => (
									<option key={stateCode} value={stateCode}>
										{stateName}
									</option>
								)
							)}
						</select>
						<div className="base-label">If you're from a different state, please join our
							<Link
								to={`/${userType}/waitlist`}
								className='waitlist-link'
							> waitlist.</Link>
						</div>

						<button
							className="confirm"
							onClick={nextStep}
							disabled={address.state !== 'MA'}
						>
							Next
						</button>

					</div>
				)}


				{step === 2 && userType === 'provider' && (
					<>
						<div className="content">
							<div className="desc center">Please enter your hourly rate.</div>
							<div className="rate">
								<input
									className="base-input"
									type="number"
									onChange={(e) => setRate(e.target.value)}
								/>
								<span>$/h</span>
							</div>
							<div className="desc center">(Required)</div>
						</div>
						<div className="content categories">
							{/* <div className="desc center">
								You could optionally add a specific hourly rate for each
								category.
								<br />
								Please select the categories of services you want to provide.
							</div> */}
							<CategoriesPicker
								selected={categories}
								selectCategory={selectCategory}
								setCategoryRate={setCategoryRate}
								rate={rate}
							/>
						</div>

						<div className="categories-list">
							<div className="sticky">
								<div className="block">
									<div className="title">
										You can choose <b>{categories.length}</b> categories:
									</div>
									{categories.map(({ title, index }) => (
										<div
											className="cat"
											key={index}
											onClick={() => selectCategory(title)}
										>
											<img src={IconCat} alt="Icon" />
											<span>{title}</span>
										</div>
									))}
								</div>

								<button
									className="confirm"
									onClick={nextStep}
									disabled={!(categories.length && rate)}
								>
									Next
								</button>
							</div>
						</div>
					</>
				)}

				{step === 3 && userType === 'provider' && (
					<div className="content">
						<h4 className="personal-info-header">
							Please enter your date of birth and work location.
						</h4>

						<div className="flex-wrap">
							<div className="dob-label">Date of Birth</div>
							<input
								id="dob"
								name="dob"
								type="date"
								className="dob-input"
								onChange={(e) => onDobChange(e.target.value)}
								value={dob}
							/>
						</div>

						<div>
							<div className="base-label">Address Line 1</div>
							<input
								id="addressLine1"
								name="addressLine1"
								type="text"
								className="base-input"
								value={address.addressLine1}
								onInput={(e) => onAddressChange(e.target.name, e.target.value)}
							/>

							<div className="base-label">Address Line 2</div>
							<input
								id="addressLine2"
								name="addressLine2"
								type="text"
								className="base-input"
								value={address.addressLine2}
								onInput={(e) => onAddressChange(e.target.name, e.target.value)}
							/>

							<div className="column-flex">
								<div>
									<div className="base-label">City</div>
									<input
										id="city"
										name="city"
										type="text"
										className="base-input"
										value={address.city}
										onInput={(e) =>
											onAddressChange(e.target.name, e.target.value)
										}
									/>
								</div>

								<div>
									<div className="base-label">State</div>
									<select
										id="state"
										name="state"
										className="base-input"
										value={address.state}
										onChange={(e) =>
											onAddressChange(e.target.name, e.target.value)
										}
									>
										<option value=""> -- Select a state --</option>
										{Object.entries(/*USStateCodes*/ { MA: 'MA' }).map(
											([stateCode, stateName]) => (
												<option key={stateCode} value={stateCode}>
													{stateName}
												</option>
											)
										)}
									</select>
									<div className="base-label">If you're from a different state, please join our
										<Link
											to={`/${userType}/waitlist`}
											className='waitlist-link'
										> waitlist.</Link>
									</div>
								</div>

								<div>
									<div className="base-label">Zip Code</div>
									<input
										id="zipCode"
										name="zipCode"
										type="text"
										className="base-input"
										value={address.zipCode}
										onInput={(e) =>
											onAddressChange(e.target.name, e.target.value)
										}
									/>
								</div>
							</div>

							<div className="base-label">Country</div>
							<input
								id="country"
								name="country"
								type="text"
								className="base-input disabled"
								value={address.country}
								onInput={(e) => onAddressChange(e.target.name, e.target.value)}
								disabled
							/>

							<div className="base-label">Country Code</div>
							<input
								id="countryCode"
								name="countryCode"
								type="text"
								className="base-input disabled"
								value={address.countryCode}
								onInput={(e) => onAddressChange(e.target.name, e.target.value)}
								disabled
							/>
						</div>

						<button
							className="confirm"
							onClick={nextStep}
							disabled={!dob || !isAddressComplete()}
						>
							Next
						</button>
					</div>
				)}

				<Loader show={isLoading} />
			</div>
		</div>
	);
};

export default Stepper;
