import io from 'socket.io-client';
import Notifications from 'store/notifications';
import Auth from 'store/auth';

const host = process.env.REACT_APP_SOCKET_HOST;

const socket = io(host, {
	transports: ['websocket'],
});

export const authSocket = () => {
	const token = localStorage.token;

	if (token && !socket.isAuth) {
		socket.emit('auth', token);
	}

	socket.isAuth = !!token;
};

socket.on('connect', authSocket);
socket.on('disconnect', () => (socket.isAuth = false));
socket.on('authResponse', (auth) => {
	socket.isAuth = auth;
	Auth.socketAuhorize(auth);
});

socket.on('newMessage', ({ message }) => {
	if (!message.isMe) {
		Notifications.create({
			title: 'New message',
			content: `You have new message from ${message.from}`,
			date: message.date_create,
		});

		Notifications.setUnreadedChats(true);
	}
});

socket.on('unreadedChats', (count) => {
	Notifications.setUnreadedChats(count);
});

export default socket;
