import axios from 'axios';
import classNames from 'classnames';
import DatesSlider from 'pages/JobCreate/DatesSlider';
import React, { useEffect, useState } from 'react';

const DatePicker = ({
	providerId,
	availabilitiesState,
	timeData,
	setTimeData,
}) => {
	const [isAvailable, setIsAvailable] = useState({
		state: '',
		message: '',
	});

	const handleTimeChange = (field, value) => {
		setTimeData({
			...timeData,
			[field]: value,
		});
	};

	useEffect(() => {
		const fetchAvailableTime = async () => {
			try {
				const { data } = await axios.get(`/jobs/availabilities`, {
					params: {
						providerId,
						date: timeData.date,
						startTime: timeData.startTime,
						endTime: timeData.endTime,
					},
				});
				setIsAvailable({
					state: `${data ? 'success' : 'failure'}`,
					message: `${data
						? 'Provider is available at this time'
						: 'Provider is already booked for this time '
						}`,
				});
			} catch (e) {
				setIsAvailable({ state: 'failure', message: e.response.data.error });
			}
		};

		if (timeData.date && timeData.startTime && timeData.endTime) {
			fetchAvailableTime();
		}
	}, [timeData.date, timeData.startTime, timeData.endTime, providerId]);

	return (
		<div className="block">
			<h2>When do you need the job done?</h2>

			<DatesSlider
				onChange={(date) => setTimeData({ ...timeData, date })}
				selected={timeData.date}
				availability={availabilitiesState}
			/>

			<p className="base-label">
				{`Select the start and end times for the job (must be within the provider's availability below  and minimum duration of the job must be at least 1 hour).`}
			</p>

			<div className="flex time-slot time-container gap-8px mt12">
				<div className="column-flex">
					<label htmlFor={`startTime`} className="label-text">
						Start
					</label>
					<input
						id={`startTime`}
						type="time"
						value={timeData.startTime}
						className="input-text"
						onChange={(e) => handleTimeChange('startTime', e.target.value)}
					/>
				</div>

				<div className="column-flex">
					<label htmlFor={`endTime`} className="label-text">
						End
					</label>
					<input
						id={`endTime`}
						type="time"
						value={timeData.endTime}
						className="input-text"
						onChange={(e) => handleTimeChange('endTime', e.target.value)}
					/>
				</div>
				<label className="base-checkbox repeat-weekly">
					<input
						type="checkbox"
						onChange={(e) =>
							setTimeData({ ...timeData, weeklyRepeat: e.target.checked })
						}
						value={timeData.weeklyRepeat}
					/>
					<div className="check" />
					<span>Repeat every week</span>
				</label>
			</div>
			<div
				className={classNames('time-available', {
					success: isAvailable.state === 'success',
					failure: isAvailable.state === 'failure',
				})}
			>
				{isAvailable.message}
			</div>
		</div>
	);
};

export default DatePicker;
