import React from 'react'
import { Link } from 'react-router-dom'
import { dateFromNow } from 'utils/helpers'

const SystemMessage = ({ item }) => {
    return (
        <div
            className={"item system"}
            key={item._id}
        >
            <div className="content">
                <div className="cloud">{item.content}<br />
                    {item.link &&
                        <Link to={item.link.path}>
                            {item.link.title}
                        </Link>
                    }
                </div>
                <div className="info">
                    <span className="date">
                        {dateFromNow(item.date_create)}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SystemMessage