const BaseEdit = ({
	value,
	onChange,
	maxLength = 500,
	rows = 7,
	placeholder = '',
	onBlur,
	name,
}) => {
	return (
		<div className="base-edit">
			<textarea
				onInput={(e) => onChange(e.target.value)}
				rows={rows}
				className="base-input"
				name={name}
				value={value}
				placeholder={placeholder}
				onBlur={onBlur}
			/>
			<div className="length">
				{value?.length || 0}/{maxLength}
			</div>
		</div>
	);
};

export default BaseEdit;
