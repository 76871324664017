import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

const BaseTabs = ({
	children,
	tabs = [],
	menu = [],
	current,
	onTab,
	sticky = false,
}) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	return (
		<div className="base-tabs">
			<div className={classNames('header', { sticky })}>
				{tabs.map(({ name, value, count }, index) => (
					<div
						className={classNames('tab', { selected: value === current })}
						onClick={() => onTab(value)}
						key={index}
					>
						{name || value}
						{count >= 0 && (
							<span>{count}</span>
						)}
					</div>
				))}

				{menu.length > 0 && (
					<div className="dropdown" ref={dropdownRef}>
						<div className="tab" onClick={() => setShowDropdown(!showDropdown)}>
							show more...
						</div>
						{showDropdown && (
							<div className="dropdown-menu">
								{menu.map(({ name, value, count }, index) => (
									<div
										className={classNames('tab', {
											selected: value === current,
										})}
										key={index}
										onClick={() => onTab(value)}
									>
										{name || value}
										{count >= 0 && (
											<span>{count}</span>
										)}
									</div>
								))}
							</div>
						)}
					</div>
				)}
			</div>

			<div className="tab-content">{children}</div>
		</div>
	);
};

export default BaseTabs;
