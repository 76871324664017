import { PayPalButtons, FUNDING } from '@paypal/react-paypal-js';



const Button = ({ fundingSource, onOrder, payee, amount, fee }) => {
	return (
		<PayPalButtons
			fundingSource={fundingSource}
			style={{
				label: 'pay',
				tagline: false,
				layout: 'vertical',
				padding: 20,
			}}
			createOrder={(data, actions) => {
				return actions.order.create({
					intent: "CAPTURE",
					application_context: {
						shipping_preference: "NO_SHIPPING",
						user_action: "PAY_NOW"
					},
					purchase_units: [{
						amount: {
							currency_code: "USD",
							value: amount
						},
						payee: {
							merchant_id: payee
						},
						payment_instruction: {
							disbursement_mode: "DELAYED",
							platform_fees: [{
								amount: {
									currency_code: "USD",
									value: fee.toFixed(2)
								}
							}]
						}
					}]
				})
			}}
			onApprove={(data, actions) => {
				return actions.order.capture().then((capture) => {
					onOrder(capture.purchase_units[0]?.payments?.captures[0]?.id);
				});
			}}
			onError={console.log}
		/>
	)
}


const PaypalButton = ({ onOrder, onError, amount, type, payee }) => {

	const original_amount = (amount / 1.2).toFixed(2)
	const fee = amount - original_amount;

	// Card fee: 2.99% + 0.49 on the platform's 20% cut
	const cardFee = fee - (amount * 0.0299 + 0.49);

	// PayPal fee: 3.49% + 0.49 on the platform's 20% cut
	const paypalFee = fee - (amount * 0.0349 + 0.49);

	return (
		<div className='paypal-pay-button'>
			<Button fundingSource={FUNDING.PAYPAL} onOrder={onOrder} payee={payee} amount={amount} fee={paypalFee} />
			<Button fundingSource={FUNDING.CARD} onOrder={onOrder} payee={payee} amount={amount} fee={cardFee} />
		</div>
	);


};

export default PaypalButton;
