const Safety = () => {
	return (
		<div className="page policy about">
			<h1>FAQs/ Safety for Emissary Marketplace Webpage</h1>
			<p>
				<b>
					How does the Emissary Health Marketplace differ from other care search
					websites?
				</b>
				<br />
				Transparency. We believe in transparency as a guiding principle, and
				customers can always clearly view pricing, the availability of
				caregivers, what their ratings are, and what services they offer.
			</p>
			<p>
				<b>
					What services are offered on the Emissary Health Marketplace? <br />
					Emissary Health caregivers provide care related to:
				</b>
				<ul className="list-dash">
					<li>Meal preparation</li>
					<li>Grocery shopping</li>
					<li>Companionship</li>
					<li>Medication management</li>
					<li>Prescription pick up/ drop off</li>
					<li>Laundry</li>
					<li>Mobility assistance</li>
					<li>Insurance navigation</li>
					<li>Medical supply organization</li>
				</ul>
			</p>
			<p>
				<b>How do I choose the right caregiver?</b> <br />
				It is important that you are able to select a caregiver that you are
				comfortable and compatible with. With that said, Emissary Health has
				diverse caregivers from different linguistic and cultural backgrounds.
				Caregivers may also enter information that makes them unique in the
				“About Me” section.
			</p>
			<p>
				Through the consumer portal, you are able to view each caregiver’s
				availability to select services that fit within your needs and schedule.
				You can also read reviews of past interactions with this caregiver.
			</p>
			<p>
				Emissary Health’s price transparency when selecting a caregiver and
				service will allow you to select a caregiver that fits within your
				budget.
			</p>
			<p>
				<b>Can people see my personal information? </b>
				<br />
				Caregiver’s personal information will not be shared with the consumer
				aside from the caregiver’s first and last name or first name and last
				initial (ex., Jane Smith or Jane S.). Consumer’s addresses and phone
				numbers will be shared with the selected caregiver after the transaction
				to allow for the caregiver to meet the consumer at their home for
				service delivery. All other personal information will remain
				confidential.
			</p>
			<p>
				<b> Do Emissary Health caregivers provide medical care? </b>
				<br />
				No. Emissary Health caregivers do not provide medical care and are not
				required to be licensed medical providers. Emissary Health caregivers
				provide home health aide/ personal care attendant levels of support in
				your home. Caregivers will not assist with tasks such as wound care,
				prescription medication administration, bathing, etc.
			</p>
			<p>
				<b>Should a caregiver be providing rides to a consumer?</b>
				<br /> Emissary Health does not advise that caregivers provide rides to
				consumers. Emissary Health is not responsible for this liability. If a
				caregiver has a task that requires transportation (i.e., grocery
				shopping, medication pick up/ drop off), it is advised that they
				complete that task alone.
			</p>
			<p>
				<b>How can I maintain my safety when a caregiver enters my home? </b>
				<br />
				It is the consumer’s responsibility to maintain their own safety when
				workers enter their home. Some tips for maintaining safety include:
				<ul className="list-dash">
					<li>
						You have the right to ask a caregiver to confirm their identity
						prior to allowing them to enter your home.
					</li>
					<li>
						You may choose to wear protective equipment (gloves, masks, and
						other face coverings) when interacting with your caregiver and can
						request that your caregiver does the same.
					</li>
					<li>
						Keep money and other valuables, as well as documentation with
						personal information, secure when anyone enters your home.
					</li>
					<li>
						Do not disclose personal information such as bank information,
						social security number, etc.
					</li>
					<li>Keep all medication securely stored. </li>
					<li>
						You may elect to have another person you trust in your home with you
						the first time you meet a new caregiver.
					</li>
					<li>Remain present while the caregiver is in your home. </li>
					<li>
						Do not give the caregiver your bank/ credit cards. If a caregiver is
						going grocery shopping for you, you should discuss payment
						arrangements ahead of time. You may wish to provide them with cash
						prior to going to the store, or wait until they arrive back with
						your groceries.
					</li>
					<li>
						The caregiver is paid through the Emissary Health Marketplace
						website when you book their services. You do not need to pay them in
						person, tip for their services, or pay any additional costs (with
						the exception of the cost of groceries/ prescription pick up).
					</li>
				</ul>
			</p>
			<p>
				<b>How can I maintain my safety when entering a consumer’s home? </b>
				It is up to the caregiver to maintain their own safety when entering a
				consumer’s home. Some tips for maintaining safety include:
				<ul className="list-dash">
					<li>
						Always let a loved one or trusted friend know where you are going
						the first time you are visiting a new consumer’s home.
					</li>
					<li>
						Have the consumer confirm their name prior to entering their home.
					</li>
					<li>Be aware of your surroundings.</li>
					<li>
						You may choose to wear protective equipment (i.e., face coverings,
						gloves, etc.) when entering a home.
					</li>
					<li>Wear appropriate clothing and non-slip shoes.</li>
					<li>
						Be cautious with animals in the home. You may ask a consumer to
						crate or seclude an animal to a different part of the home for your
						safety during the visit.
					</li>
					<li>Do not bring valuables into the consumer’s home.</li>
				</ul>
			</p>
			<p>
				<b>
					How can I report situations of suspected abuse/ safety issues in the
					home?
				</b>
				<br />
				Emissary Health is not responsible for the reporting of abuse or safety
				issues in the home. If there is an emergency, please dial 9-1-1. If a
				caregiver feels they should report suspected abuse/ safety concerns,
				they may contact their state’s protective services department directly.
				<br />
				<b>What is the best contact for Emissary Health support? </b>
				<br />
				For any additional questions or support, please contact us directly at
				<br />
				<a className="email" href="mailto:support@emissaryhealth.org">
					support@emissaryhealth.org.
				</a>
			</p>
		</div>
	);
};
export default Safety;
