import { useCallback } from 'react';

const BaseRadio = ({
	onChange,
	onValue,
	text,
	name,
	value,
	checked,
	className,
}) => {
	const processChange = useCallback(
		(e) => {
			if (onChange) onChange(e);
			if (onValue) onValue(e.target.checked, e.target.value);
		},
		[onChange, onValue]
	);

	return (
		<label className="base-radio">
			<input
				type="radio"
				name={name}
				value={value}
				checked={checked}
				onChange={processChange}
			/>
			<div className="check" />
			<span className={className}>{text}</span>
		</label>
	);
};

export default BaseRadio;
