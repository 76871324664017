import axios from 'axios';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { parseError } from 'utils/helpers';
import { useTimeout } from 'utils/hooks';
import { useStore } from 'store';

import Loader from 'components/Base/Loader';
import GoogleSign from 'components/GoogleSign/GoogleSign';
import Stepper from '../../components/Stepper/Stepper';
import Dropdown from 'components/Base/Dropdown';
import IconArrow from './img/cat-arrow-down.svg';

const Auth = () => {
	/** STATES */
	const [userType, setUserType] = useState('customer');
	const [step, setStep] = useState(0);
	const [error, setError] = useState({});
	const [termsAccept, setTermsAccept] = useState(false);
	const [termsError, setTermsError] = useState(false);
	const [isGoogleAuth, setIsGoogleAuth] = useState(false);

	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');
	const [cost, setCost] = useState([]);
	const [rate, setRate] = useState();
	const [dobState, setDobState] = useState(undefined);
	const [addressState, setAddressState] = useState({
		addressLine1: '',
		addressLine2: '',
		city: '',
		state: '',
		zipCode: '',
		country: 'United States of America',
		countryCode: 'US',
	});
	const [finishing, setFinishing] = useState(false);

	/** CONSTANTS */
	const maxSteps = useMemo(() => (userType === 'customer' ? 2 : 3), [userType]);
	const store = useStore();
	const termsTimer = useTimeout();
	const navigate = useNavigate();
	const { type: initUserType } = useParams();
	const userTypeMapping = {
		Customer: 'customer',
		Caregiver: 'provider'
	};

	useEffect(() => {
		if (initUserType) {
			setUserType(initUserType);
		}
	}, [initUserType]);

	const signInfo = useMemo(
		() => ({
			name,
			surname,
			email,
			password,
			rate,
			cost,
			dob: dobState,
			address: addressState,
		}),
		[
			name,
			surname,
			email,
			password,
			rate,
			cost,
			dobState,
			addressState,
		]
	);

	const showSignin = useCallback(() => {
		store.auth.toggleModal(true);
	}, [store.auth]);

	const googleSing = useCallback((data) => {
		if (data.email) {
			setIsGoogleAuth(true);
			setEmail(data.email);
			setPassword(null);
			if (data.given_name) setName(data.given_name);
			if (data.family_name) setSurname(data.family_name);
		}
	}, []);

	const finishSignup = useCallback(() => {
		setFinishing(true);
		axios
			.post(`/auth/signup/${userType}`, signInfo)
			.then(({ data }) => {
				store.auth.authorize(data.token, userType);
				localStorage.setItem('hasSignedUp', true);
				if (userType === 'provider') {
					navigate('/profile');
				} else {
					navigate('/');
				}
			})
			.catch((e) => {
				setStep(0);
				setError(parseError(e));
				setFinishing(false);
			});
	}, [userType, signInfo, navigate, store.auth]);

	const startSignup = useCallback(() => {
		if (!termsAccept) {
			setTermsError(true);

			return termsTimer(() => {
				setTermsError(false);
			}, 3000);
		}

		if (!isGoogleAuth && signInfo.password !== passwordRepeat) {
			return setError({ type: 'passwords', message: 'do not match' });
		}

		axios
			.post(`/auth/signup/${userType}/validate`, signInfo)
			.then(() => {
				if (isGoogleAuth) {
					if (userType === 'provider') setStep(2);
					if (userType === 'customer') /*finishSignup();*/setStep(2)
				} else {
					setStep(1);
				}
			})
			.catch((e) => {
				setError(parseError(e));
			});
	}, [
		userType,
		passwordRepeat,
		isGoogleAuth,
		termsAccept,
		termsTimer,
		signInfo,
		finishSignup,
	]);

	// const selectCategory = useCallback((cat) => {
	// 	setTags((cats) => {
	// 		const index = cats.indexOf(cat);
	// 		index >= 0 ? cats.splice(index, 1) : cats.push(cat);
	// 		return [...cats];
	// 	});
	// }, []);

	const selectCategory = useCallback((cat) => {
		setCost((cost) => {
			const index = cost.findIndex((cost) => cost.title === cat);

			if (index >= 0) {
				const updatedCost = [...cost.slice(0, index), ...cost.slice(index + 1)];
				return updatedCost;
			} else {
				const newCost = { title: cat, price: '' };
				return [...cost, newCost];
			}
		});
	}, []);

	const setCategoryRate = useCallback((cat, price) => {
		setCost((cats) => {
			const index = cats.findIndex((item) => item.title === cat);
			if (index >= 0) {
				cats[index] = { title: cat, price };
			}
			return [...cats];
		});
	}, []);

	// const changeCost = useCallback((e, tag) => {
	// 	setTags((prev) => {
	// 		prev.map((item) => {
	// 			if (item.title === tag) {
	// 				item.price = e.target.value
	// 			}
	// 		})
	// 		return [...prev]
	// 	})
	// }, []);

	const onAddressChange = useCallback((name, value) => {
		setAddressState((previousState) => ({
			...previousState,
			[name]: value,
		}));
	}, []);

	const onDobChange = useCallback((dob) => {
		setDobState(dob);
	}, []);

	const onNextStep = useCallback(() => {
		if (step >= maxSteps) {
			return finishSignup();
		} else {
			setStep((step) => step + 1);
		}
	}, [step, maxSteps, finishSignup]);

	return (
		<div className="sign-component">
			{step === 0 && (
				<>
					<div className="side left">
						<span>Need a helping hand?</span>
						You're not alone. Get an Emissary today.
						<br />
						<br />
						And yes, we background check. Every time.
					</div>

					<div className="side right">
						<div className="form">
							<h4>Create an account</h4>
							<div className="signin">
								Already have an account? <a onClick={showSignin}>Sign in</a>
							</div>

							<div className="line">
								<div className="field">
									<div className='label'>Are you a <b>Customer</b> or <b>Caregiver?</b></div>
									<Dropdown
										items={Object.keys(userTypeMapping)}
										autoclose={true}
										onChange={(val) => setUserType(userTypeMapping[val])}
									>
										{Object.keys(userTypeMapping).find(key => userTypeMapping[key] === userType)}
										<img src={IconArrow} className="arrow" alt="Arrow" />
									</Dropdown>
								</div>
							</div>

							<GoogleSign onUserInfo={googleSing} type="up" />

							<div className="or">
								<span>or</span>
							</div>

							<div className="line">
								<div className="field half">
									<div className="label">Name</div>
									<input
										type="text"
										onChange={(e) => setName(e.target.value)}
										value={name}
									/>
								</div>

								<div className="field half">
									<div className="label">Surname</div>
									<input
										type="text"
										onChange={(e) => setSurname(e.target.value)}
										value={surname}
									/>
								</div>
							</div>

							<div className="line">
								<div className="field">
									<div className="label">Email</div>
									<input
										type="text"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
										disabled={isGoogleAuth}
									/>
								</div>
							</div>

							{!isGoogleAuth && (
								<div className="line">
									<div className="field half">
										<div className="label">Password</div>
										<input
											type="password"
											onChange={(e) => setPassword(e.target.value)}
										/>
									</div>

									<div className="field half">
										<div className="label">Repeat password</div>
										<input
											type="password"
											onChange={(e) => setPasswordRepeat(e.target.value)}
										/>
									</div>
								</div>
							)}

							<div className="error-message">
								<b>{error.type}</b>
								{error.message}
							</div>

							<label className={classNames('terms', { error: termsError })}>
								<input
									type="checkbox"
									onChange={(e) => setTermsAccept(e.target.checked)}
								/>
								<div className="check"></div>I agree to the{' '}
								<Link to="/terms">Terms</Link> and{' '}
								<Link to="/policy">Privacy Policy</Link>
							</label>

							<button className="submit" onClick={startSignup}>
								Sign Up
							</button>
						</div>
					</div>
				</>
			)}

			{step > 0 && !finishing && (
				<Stepper
					step={step}
					nextStep={onNextStep}
					userType={userType}
					signInfo={signInfo}
					finish={finishSignup}
					categories={cost}
					selectCategory={selectCategory}
					isGoogleAuth={isGoogleAuth}
					dob={dobState}
					onDobChange={onDobChange}
					address={addressState}
					onAddressChange={onAddressChange}
					setCategoryRate={setCategoryRate}
					setRate={setRate}
					rate={rate}
				/>
			)}

			{step > 0 && finishing && <Loader />}
		</div>
	);
};

export default observer(Auth);
