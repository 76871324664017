import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { useStore } from 'store';
import { replacementFields } from 'utils/constants';

const StatusBar = () => {
	const store = useStore();

	const replacedFields = useMemo(() => {
		return store.auth.profile.stringsToFill.map(word => replacementFields[word] || word).join(', ');
	}, [store.auth.profile.stringsToFill]);

	return (
		<div className="status-bar">
			{!store.auth.user.access && (
				<div className="message">
					{store.auth.user.isProvider ? (
						<>
							Please complete the following information in your
							<Link to={'/profile'}> profile </Link>
							in order to begin receiving jobs: {replacedFields}
						</>
					) : (
						<>
							Please complete the following information in your
							<Link to={'/profile'}> profile </Link>
							to begin scheduling jobs: {replacedFields}
						</>
					)}
				</div>
			)}
		</div>
	)
}

export default observer(StatusBar)