import React, { useState } from 'react'
import Loader from 'components/Base/Loader';
import axios from 'axios';
import { useParams } from 'react-router-dom';




const Waitlist = () => {
	const [email, setEmail] = useState('')
	const [error, setError] = useState()
	const [loading, setLoading] = useState(false)
	const [isJoined, setIsJoined] = useState(false)

	const { type } = useParams()


	const joinWaitlist = () => {
		if (!email) {
			setError('Email is required')
			return
		}
		setLoading(true)

		axios.post('/auth/join/waitlist', { email, type }).then(({ data }) => {
			if (data) {
				setIsJoined(true)
			}
		})
			.catch((e) => {
				setError(e.response?.data.error.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<div className='sign-component'>
			<div className='stepper'>
				<div className="header">
					<div className="title">Join our waitlist</div>
					<div className="desc">At the moment, our platform could only be used by Customers and Caregivers in the state of Massachusetts. <br />
						If you're outside MA, please join the waitlist for updates.</div>
				</div>
				<div className="wrap">
					{!isJoined ? (
						<>
							<div className="base-label">Please, leave your Email</div>
							<input
								id="email"
								name="email"
								type="email"
								className="base-input email"
								onInput={(e) => setEmail(e.target.value)}
								value={email}
								placeholder="example@gmail.com"
							/>
							<button
								className="confirm"
								onClick={joinWaitlist}
							>
								Join the waitlist
							</button>
							{error && (
								<div className="error">
									<b>{error}</b>
								</div>
							)}
						</>
					) : (
						<>
							<div className="title-complete">You have successfully joined the waitlist</div>
							<div className="desc">We will notify you when our platform is available in your area.</div>
						</>
					)}
				</div>
			</div>
			<Loader show={loading} />
		</div>
	)
}

export default Waitlist