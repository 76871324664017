import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import Loader from 'components/Base/Loader';
import Search from './Search';
import CardJob from './CardJob';
import CardProvider from './CardProvider';
import StatusBar from './StatusBar';
import Notifications from 'store/notifications';

const Home = ({ type }) => {
	const [list, setList] = useState([]);
	const [fetching, setFetching] = useState(true);
	const [searchParams, setSearchParams] = useState({});
	const [currentType, setCurrentType] = useState(null);

	useEffect(() => {
		setCurrentType(type);
		setFetching(true);
		setList([]);

		axios
			.get(`/${type}`, {
				params: searchParams,
			})
			.then(({ data }) => {
				setList(data);
			})
			.catch((e) => {
				Notifications.create({
					title: 'Error',
					content: e.response?.data?.error || 'Something went wrong',
				});
			})
			.finally(() => {
				setFetching(false);
			});
	}, [searchParams, type]);

	const changeSearch = useCallback((search) => {
		const params = {};
		for (const name in search) {
			if (search[name]) params[name] = search[name];
		}
		setSearchParams(params);
	}, []);

	return (
		<div className={`home ${type} page`}>
			<StatusBar />
			<h1>Find a {type === 'providers' ? 'caregiver' : type}</h1>

			<Search onSearch={changeSearch} />

			<Loader show={fetching} />

			{!list.length && <div className="empty">Nothing found...</div>}

			{type === currentType && list.length > 0 && (
				<div className="list">
					{list.map((item, index) => {
						if (type === 'jobs') {
							return <CardJob data={item} key={index} />;
						}

						if (type === 'providers') {
							return <CardProvider data={item} key={index} />;
						}

						return null;
					})}
				</div>
			)}
		</div>
	);
};

export default Home;
