import { Link } from 'react-router-dom';
import LinkedInIcon from './img/linkedin.svg';

const Footer = () => {
	return (
		<div className="app-footer">
			<div className="wrap">
				<div className="links">
					<div className="pages">
						<Link to="/about">About us</Link>
						<Link to="/about/caregivers">For Caregivers</Link>
						<Link to="/about/how">How It Works</Link>
						<Link to="/about/safety">FAQs & Safety</Link>
					</div>
					<div className="social">
						<a
							href="https://www.linkedin.com/company/emissary-health/about/"
							target="_blank"
							rel="noreferrer"
						>
							<img src={LinkedInIcon} alt="Linked In" />
						</a>
						{/* <a href="//facebook.com">
							<img src={IconFacebook} alt="Facebook" />
						</a>
						<a href="//twitter.com">
							<img src={IconTwitter} alt="Twitter" />
						</a>
						<a href="//instagram.com">
							<img src={IconInstagram} alt="Instagram" />
						</a>
						<a href="//youtube.com">
							<img src={IconYoutube} alt="Youtube" />
						</a> */}
					</div>
				</div>

				<div className="bottom">
					<div>© 2024 Emissary Health, Inc. All rights reserved.</div>

					<div className="policy">
						{/* <Link to="/terms">Terms Of Use</Link> */}
						<a
							href="https://emissary-health-storage.s3.amazonaws.com/Emissary_Health_Terms_of_Use_2023.pdf"
							target="_blank"
							rel="noreferrer"
						>
							Terms of Use
						</a>
						{/* <Link to="/policy">Privacy Policy</Link> */}
						<a
							href="https://emissary-health-storage.s3.amazonaws.com/Emissary_Health_Privacy_Policy_10.12.2023.pdf"
							target="_blank"
							rel="noreferrer"
						>
							Privacy Policy
						</a>
					</div>
				</div>
				<p className="disclaimer">
					Emissary Health does not employ any caregivers on this platform and is
					not responsible for the conduct of any user of our site. All
					information is created by users of our site and not generated or
					verified by Emissary Health. You must complete your own diligence to
					ensure the job or caregiver you choose is appropriate for your needs
					and complies with applicable federal and local laws. We recommend that
					all users take precautions to ensure their privacy and safety at all
					times.
				</p>
			</div>
		</div>
	);
};

export default Footer;
