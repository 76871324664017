import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { getAvatar } from 'utils/helpers';

import BaseTabs from 'components/Base/BaseTabs';
import TabGeneral from './TabGeneral';
import TabProvider from './TabProvider';
import TabPassword from './TabPassword';
import TabBackgroundCheck from './TabBackgroundCheck';
// import TabPayments from "./TabPayments";

const Tabs = {
	provider: [
		{ value: 'General Information' },
		{ value: 'Provider Profile' },
		{ value: 'Change Password' },
		{ value: 'Background Check' },
		// {name: "Earnings history"},
	],
	customer: [
		{ value: 'General Information' },
		// {name: "Payment history"},
		{ value: 'Change password' },
	],
};

const ProfileEdit = () => {
	const [tab, setTab] = useState(null);
	const [currentTabs, setCurrentTabs] = useState([]);

	const store = useStore();
	const { accessToPlatform } = store.auth;
	const { isProvider, isCustomer, type, id, name, surname, avatar } =
		store.auth.user;

	useEffect(() => {
		if (type) {
			const tabs = Tabs[type];
			setCurrentTabs(tabs);
			setTab(!accessToPlatform ? tabs[tabs.length - 1].value : tabs[0].value);
		}
	}, [type, accessToPlatform]);

	return (
		<div className="page profile-edit">
			<div className="profile-user">
				<div className="avatar">
					<img src={getAvatar(avatar)} alt="User" />
				</div>

				<div className="info">
					<div className="name">
						{name} {surname}
					</div>
					<div className="type">{type}</div>
				</div>

				{accessToPlatform && (
					<Link className="button" to={`/${type}s/${id}`}>
						Profile preview
					</Link>
				)}
			</div>

			<BaseTabs tabs={currentTabs} onTab={setTab} current={tab}>
				{isProvider && (
					<>
						{tab === Tabs.provider[0].value && <TabGeneral />}
						{tab === Tabs.provider[1].value && <TabProvider />}
						{/* {tab === Tabs.provider[2] && <TabPayments />} */}
						{tab === Tabs.provider[2].value && <TabPassword />}
						{tab === Tabs.provider[3].value && <TabBackgroundCheck />}
					</>
				)}

				{isCustomer && (
					<>
						{tab === Tabs.customer[0].value && <TabGeneral />}
						{/* {tab === Tabs.customer[1] && <TabPayments />} */}
						{tab === Tabs.customer[1].value && <TabPassword />}
					</>
				)}
			</BaseTabs>
		</div>
	);
};

export default observer(ProfileEdit);
