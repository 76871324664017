import classNames from 'classnames'
import React from 'react'
import { dateFromNow, getAvatar } from 'utils/helpers'

const UsersMessage = ({ item }) => {
    return (
        <div
            className={classNames('item', { my: item.isMe })}
            key={item._id}
        >
            <img src={getAvatar()} alt="Avatar" className="avatar" />
            <div className="content">
                <div className="cloud">{item.content}</div>
                <div className="info">
                    <span className="date">
                        {dateFromNow(item.date_create)}
                    </span>
                    <span>{item.isReaded ? 'Viewed' : 'Unreaded'}</span>
                </div>
            </div>
        </div>
    )
}

export default UsersMessage