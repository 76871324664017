import { JobEndTimes } from './constants';
import moment from 'moment';
import Notifications from 'store/notifications';
import { emptyTimeSlots } from 'components/AvailabilityInput/availability-input.constants';

export const getJobTimes = (time) => {
	return time ? `${time}:00 - ${JobEndTimes[time]}:00` : 'any time';
};

export const inNodePath = (path, name) => {
	return !!path.find((p) => p.classList?.contains(name));
};

export const changePageTitle = (title) => {
	document.title = title ? `Emissary | ${title}` : `Emissary`;
};

export const parseError = (err) => {
	const error = err.response?.data?.error;

	if (error) {
		return {
			type: error.field ?? 'Error',
			message: error.message ?? error,
		};
	} else {
		return {
			type: 'Error',
			message: 'Unexpected error',
		};
	}
};

export const getAvatar = (avatar) => {
	if (!avatar) return '/img/avatar.svg';

	if (avatar.startsWith('/')) {
		if (process.env.NODE_ENV === 'production') {
			return avatar;
		}

		return `${process.env.REACT_APP_SOCKET_HOST}${avatar}`;
	}

	return avatar;
};

export const errorNotification = (error, parse = false) => {
	if (parse) {
		error = parseError(error);
	}

	Notifications.create({
		type: 'error',
		title: error?.type || 'Error',
		content: error?.message || error,
	});
};

export const dateFromNow = (date) => {
	const current = moment();
	const creation = moment(date);
	const diff = current.diff(creation, 'days');

	if (diff > 0) {
		return creation.format('DD.MM.YYYY');
	} else {
		return creation.startOf('minute').fromNow();
	}
};

if (!('path' in Event.prototype)) {
	Object.defineProperty(Event.prototype, 'path', {
		get: function () {
			var path = [];
			var currentElem = this.target;
			while (currentElem) {
				path.push(currentElem);
				currentElem = currentElem.parentElement;
			}
			if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
				path.push(document);
			if (path.indexOf(window) === -1) path.push(window);
			return path;
		},
	});
}

export const validateAvailabilityTime = (availability, date, startTime, endTime) => {
	const dayOfWeek = moment(date).format("dddd");
	const timeSlots = availability[dayOfWeek];
	if (!timeSlots?.length) return false;

	const inputStart = moment(startTime, "hh:mm");
	const inputEnd = moment(endTime, "hh:mm");
	if (inputStart.isSameOrAfter(inputEnd)) return false;

	const duration = moment.duration(inputEnd.diff(inputStart));
	const hours = duration.asHours();
	if (hours < 1) return false;

	return timeSlots.some((slot) => {
		const slotStart = moment(slot.startTime, "hh:mm");
		const slotEnd = moment(slot.endTime, "hh:mm");

		return (
			inputStart.isSameOrAfter(slotStart) && inputEnd.isSameOrBefore(slotEnd)
		);
	});
};

export const generateCSV = function (dataArray) {
	if (!dataArray || !dataArray.length) return;

	const headers = Object.keys(dataArray[0]);
	const csvRows = [headers.join(',')];

	dataArray.forEach((data) => {
		const values = headers.map(header => data[header]);
		csvRows.push(values.join(','));
	});

	return csvRows.join('\n');
};

export const getAvailabilities = (data) => {
	const availabilities = structuredClone(emptyTimeSlots)
	data.availabilities.forEach(({ dayOfWeek, ...rest }) => {
		if (dayOfWeek in availabilities) {
			const exists = availabilities[dayOfWeek].some(
				(item) => item.startTime === rest.startTime && item.endTime === rest.endTime
			);
			if (!exists) {
				availabilities[dayOfWeek].push({ ...rest });
			}
		}
	});

	Object.keys(availabilities).forEach((day) => {
		availabilities[day].sort((a, b) => a.startTime.localeCompare(b.startTime));
	});

	return availabilities;
};
