import { Link } from 'react-router-dom';

const HowItWorks = () => {
	return (
		<div className="page policy about">
			<h1>How It Works</h1>

			<h2>5 steps for customers: </h2>

			<div className="box blue">
				<span>1</span> Set Up Account & Payment Method
			</div>
			<div className="box blue">
				<span>2</span> Post a Task or Search Caregivers in your Area
			</div>
			<div className="box blue">
				<span>3</span> Schedule a Service to Begin Messaging the Caregiver of
				Your Choice
			</div>
			<div className="box blue">
				<span>4</span> Receive Service, Rate and Repeat
			</div>

			<Link to="/auth/customer" className="sign">
				Sign up as a customer
			</Link>

			<h2>5 steps for caregivers:</h2>

			<div className="box blue">
				<span>1</span> Set Up Account & Profile
			</div>
			<div className="box blue">
				<span>2</span> Upload State and/or Federal Credentials, and Complete
				Background Screen
			</div>
			<div className="box blue">
				<span>3</span> Manage inbound Service Requests and Messages
			</div>
			<div className="box blue">
				<span>4</span> Respond to Tasks Posted in your Area
			</div>
			<div className="box blue">
				<span>5</span> Deliver Service, Rate and Repeat
			</div>

			<Link to="/auth/provider" className="sign">
				Sign up as a caregiver
			</Link>
		</div>
	);
};

export default HowItWorks;
