import axios from 'axios';
import React, { useState, useEffect, useRef, useMemo, useCallback, useLayoutEffect } from 'react';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';

import StatusBar from './StatusBar';

const CalendarProvider = () => {
	const [jobs, setJobs] = useState([]);
	const [jobsOnDate, setJobsOnDate] = useState([]);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [registerDate, setRegisterDate] = useState();
	const jobsRef = useRef(null);

	const [maxDate] = useState(() => {
		const date = new Date();
		date.setDate(date.getDate() + 40);
		return date;
	});

	useEffect(() => {
		axios.get('/profile/bids').then(({ data }) => {
			setJobs(data);
		});

		axios.get('/providers/register-date').then(({ data }) => {
			setRegisterDate(data);
		});
	}, []);

	const onSelectDate = useCallback((date) => {
		const filtered = jobs.filter(job => new Date(job.date).toDateString() === date.toDateString());
		setJobsOnDate(filtered);
		setSelectedDate(date);
	}, [jobs]);

	useLayoutEffect(() => {
		if (jobsOnDate.length && jobsRef.current) {
			jobsRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [jobsOnDate])

	return (
		<div className='page calendar'>
			<StatusBar />
			<Calendar
				onChange={onSelectDate}
				value={selectedDate}
				maxDate={maxDate}
				minDate={new Date(registerDate?.registrationDate)}
				tileDisabled={({ date, view }) =>
					view === 'month' && (date < new Date(registerDate?.registrationDate) || date > maxDate)
				}
				tileClassName={({ date, view }) =>
					view === 'month' &&
					jobs.some(
						(job) => new Date(job.date).toDateString() === date.toDateString()
					) && 'booked'
				}
			/>
			<div className='jobs' ref={jobsRef}>
				<h2>Jobs on {selectedDate.toDateString()}:</h2>
				{jobsOnDate.length ? (
					jobsOnDate.map((job) => (
						<div className='job' key={job._id}>
							<div className="details">
								<h3><Link to={`/jobs/${job._id}`}>{job.title}</Link></h3>
								<p><span>Details: </span>{job.description}</p>
								<p><span>Price: </span>${job.price}</p>
							</div>

							<div className="status">
								<p><span>Job time: </span>{job.time.startTime}-{job.time.endTime}</p>
								<p><span>Status: </span>{job.status.replace('_', ' ')} {job.status === "active" && "(not funded)"}</p>
							</div>
						</div>
					))
				) : (
					<div className="error">No jobs booked at this time.</div>
				)}
			</div>
		</div>
	);
};

export default CalendarProvider;
