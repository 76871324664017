import classNames from 'classnames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import IconDone from './img/done-white.svg';

const Completed = ({
	show,
	setShow,
	duration = 2000,
	redirect = null,
	onHide = null,
}) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setShow(false);
				if (redirect) {
					navigate(redirect);
				}
				if (onHide) {
					onHide();
				}
			}, duration);
		}
	}, [navigate, show, setShow, redirect, duration, onHide]);

	return (
		<div className={classNames('base-completed', { show: show })}>
			<div className="icon">
				<img src={IconDone} alt="Completed" />
			</div>
		</div>
	);
};

export default Completed;
