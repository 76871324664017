import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

import IconGoogle from './google.svg';

const GoogleSign = ({ onSign, onUserInfo, type }) => {
	const signTry = useGoogleLogin({
		onSuccess: async (googleData) => {
			if (onSign) onSign(googleData);

			// const { data } = await axios.get("https://people.googleapis.com/v1/people/me?personFields=names,phoneNumbers,addresses,emailAddresses", {
			// 	headers: { Authorization: `Bearer ${googleData.access_token}` }
			// });

			if (onUserInfo) {
				const { data } = await axios.get(
					'https://www.googleapis.com/oauth2/v3/userinfo',
					{
						headers: { Authorization: `Bearer ${googleData.access_token}` },
					}
				);
				onUserInfo(data);
			}
		},
		onError: (e) => console.log('Google error', e),
	});

	return (
		<button className="google-sign" onClick={signTry}>
			<div className="icon">
				<img src={IconGoogle} alt="Google" />
			</div>
			Sign {type} with Google
		</button>
	);
};

export default GoogleSign;
