import { Link } from 'react-router-dom';

import TagsSplit from './TagsSplit';

import IconLocation from './img/location.svg';
import { getAvatar } from 'utils/helpers';

const CardProvider = ({ data }) => {
	return (
		<Link to={`/providers/${data._id}`} className="card provider">
			<div className="title">
				<img src={getAvatar(data.avatar)} alt="User" className="avatar" />
				<div className="title-info">
					<div className="name">{data.name} {data.surname}</div>
					<div className="category">
						<img src={IconLocation} alt="Location" />
						<span>Zip: {data.location.zipCode}</span>
					</div>
				</div>
			</div>


			<div className="description">{data.about}</div>

			<TagsSplit tags={data.cost.map((item) => item.title)} max={38} />

			{/* <div className="rate">
				<span>Hourly rate:</span>
				<div className="price">{data.rate}$/h</div>
			</div> */}
		</Link>
	);
};

export default CardProvider;
